import React from "react";
import "../styles/Academics.css";

import logo from "../assets/rising-star-logo.jpg";
import Cricket from "../assets/Cricket.jpg";

import Football from "../assets/Football.jpg";

import Basketball from "../assets/Basketball.jpg";

import Athletics from "../assets/Athletics.avif";

import Yoga from "../assets/Yoga.webp";

import Literary from "../assets/Literary Club.png";

import drama from "../assets/Drama Club.jpg";

import music from "../assets/Music Band.avif";

import environmentclub from "../assets/Environment Club.avif";

import health from "../assets/Health Awareness Campaigns.png";

// import Science from "../assets/Science and Math Fairs";

import SportsTournaments from "../assets/Sports Tournaments.avif";

import Cultural from "../assets/Cultural Festivals.webp";

import scienceAndSo from "../assets/Science Society.jpg";

import ArtandCraftClub from "../assets/Art and Craft Club.webp";

import sciMath from "../assets/sci-math.webp";

function Academics() {
  return (
    <div className="academics">
      <h1 className="title">Academics</h1>

      <section className="curriculum">
        <h3>Curriculum Overview</h3>
        <p>As per CBSE Pattern.</p>
      </section>

      <section className="departments">
        <h3>Departments</h3>
        <p>Information about different academic departments.</p>
      </section>

      <section className="extracurricular">
        <h3>Extracurricular Activities</h3>
        <p>
          At Rising Stars Public School, we believe that education goes beyond
          the classroom. Our diverse range of extracurricular activities fosters
          creativity, teamwork, and personal growth among students.
          Participation in these activities helps develop essential life skills,
          promotes physical health, and encourages social responsibility.
        </p>

        <div className="clubs-societies">
          <h3>Clubs and Societies</h3>

          <ul className="performing-arts-list">
            {[
              {
                name: "Literary Club",
                img: Literary, // Replace with actual logo URL
                msg: "Engage in creative writing, debates, and book discussions to enhance communication skills.",
              },
              {
                name: "Science Society",
                img: scienceAndSo, // Replace with actual logo URL
                msg: "Explore scientific concepts through experiments, projects, and competitions",
              },
              {
                name: "Art and Craft Club",
                img: ArtandCraftClub, // Replace with actual logo URL
                msg: "Unleash your creativity with various artistic mediums, from painting to sculpture.",
              },
            ].map((sport) => (
              <li key={sport.name} className="performing-arts-item">
                <img src={sport.img} alt={sport.name} className="sport-image" />
                <div className="sport-description">
                  <strong>{sport.name}</strong>{" "}
                  {/* Sport name comes after the image */}
                </div>
                <p className="sport-deccription-p">{sport.msg}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className="sports">
          <h3>Sports</h3>
          <ul className="sports-list">
            {[
              {
                name: "Cricket",
                img: Cricket, // Replace with actual logo URL
              },
              {
                name: "Football",
                img: Football, // Replace with actual logo URL
              },
              {
                name: "Basketball",
                img: Basketball, // Replace with actual logo URL
                description:
                  "A team sport in which two teams, most commonly of five players each, opposing one another on a rectangular court.",
              },
              {
                name: "Athletics",
                img: Athletics, // Replace with actual logo URL
              },
              {
                name: "Yoga",
                img: Yoga, // Replace with actual logo URL
                description:
                  "A group of physical, mental, and spiritual practices or disciplines aimed at attaining a state of permanent peace.",
              },
            ].map((sport) => (
              <li key={sport.name} className="sport-item">
                <img src={sport.img} alt={sport.name} className="sport-image" />
                <div className="sport-description">
                  <strong>{sport.name}</strong>{" "}
                  {/* Sport name comes after the image */}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="performing-arts">
          <h3>Performing Arts</h3>
          <ul className="performing-arts-list">
            {[
              {
                name: "Drama Club",
                img: drama, // Replace with actual logo URL
              },
              {
                name: "Music Band",
                img: music, // Replace with actual logo URL
              },
            ].map((sport) => (
              <li key={sport.name} className="performing-arts-item">
                <img src={sport.img} alt={sport.name} className="sport-image" />
                <div className="sport-description">
                  <strong>{sport.name}</strong>{" "}
                  {/* Sport name comes after the image */}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="community-service">
          <h3>Community Service</h3>
          <p>
            Engage with the community through our service projects that promote
            social awareness and responsibility. Activities include:
          </p>

          <ul className="performing-arts-list">
            {[
              {
                name: "Environment Club",
                img: environmentclub, // Replace with actual logo URL
              },
              {
                name: "Health Awareness Campaigns",
                img: health, // Replace with actual logo URL
              },
            ].map((sport) => (
              <li key={sport.name} className="performing-arts-item">
                <img src={sport.img} alt={sport.name} className="sport-image" />
                <div className="sport-description">
                  <strong>{sport.name}</strong>{" "}
                  {/* Sport name comes after the image */}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="competitions-events">
          <h3>Competitions and Events</h3>
          <p>
            Students are encouraged to participate in various inter-school
            competitions, including:
          </p>

          <ul className="performing-arts-list">
            {[
              {
                name: "Science and Math Fairs",
                img: sciMath, // Replace with actual logo URL
              },
              {
                name: "Cultural Festivals",
                img: Cultural, // Replace with actual logo URL
              },
              {
                name: "Sports Tournaments",
                img: SportsTournaments, // Replace with actual logo URL
              },
            ].map((sport) => (
              <li key={sport.name} className="performing-arts-item">
                <img src={sport.img} alt={sport.name} className="sport-image" />
                <div className="sport-description">
                  <strong>{sport.name}</strong>{" "}
                  {/* Sport name comes after the image */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="conclusion">
        <h3>Conclusion</h3>
        <p>
          At Rising Stars Public School, we believe that extracurricular
          activities are essential for developing well-rounded individuals. We
          invite all students to explore their interests, develop new skills,
          and build lasting friendships through our diverse programs. Join us in
          creating a lively and engaging school environment!
        </p>
      </section>
    </div>
  );
}

export default Academics;
