import React from "react";
import "../styles/Footer.css";
import { Margin } from "@mui/icons-material";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="/privacy">Privacy Policy</a>
        <a href="/terms">Terms of Service</a>
        <p style={{ textAlign: "center", margin: "0 auto", padding: "0 10px" }}>
          &copy; 2024 Rising Stars Public School. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
