import React from "react";
import "../styles/Admissions.css";

function Admissions() {
  return (
    <div className="admissions">
      <h1>Admissions</h1>
      <section className="admission-process">
        <h2>Admissions</h2>
        <p>
          Admission for 2025-2026 start from January 2025 for Nursery - Class 8.{" "}
        </p>
      </section>
      <section className="requirements">
        <h2>Requirements</h2>
        <p>
          The following documents are required as part of the admission
          procedure for every student applying for admission to Rahul
          International School:
        </p>
        <ul>
          <li>Birth Certificate of the Student</li>
          <li>Identity Proof of Parents - Aadhaar Card</li>
          <li>
            Residence Proof (any one of the following):
            <ul>
              <li>Ration Card</li>
              <li>Passport</li>
              <li>Voter Card</li>
              <li>Driving Licence</li>
              <li>Aadhaar Card</li>
            </ul>
          </li>
          <li>
            Passport Size Photographs:
            <ul>
              <li>3 copies for the student</li>
              <li>1 copy each of the father and mother</li>
            </ul>
          </li>
          <li>
            Transfer Certificate and Report Card from the Previous School
            <br />
            <em>(Not required for admission in Nursery/LKG/UKG/Class 1)</em>
          </li>
        </ul>
      </section>

      <section className="deadlines">
        <h2>Deadlines</h2>
        <p>Important dates and deadlines for admission.</p>
      </section>
    </div>
  );
}

export default Admissions;
