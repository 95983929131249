import React from "react";
import "../styles/AboutUs.css";

function AboutUs() {
  return (
    <div className="about-us">
      <h1 className="about-us-title">About Us</h1>
      <div className="about-us-sections">
        <section className="school-history">
          <h2>Our History</h2>
          <p>
            The Rising Stars Public School is built on a strong foundation of
            visionary ideals and a commitment to educational excellence. It is
            founded in 2024 with the mission to create a dynamic learning
            environment where students can thrive academically, socially, and
            personally. Our founders, Mr. Kirti Singh (Retd IFS) and Mrs.
            Santosh Singh (Secretary, Samaj Sewa Trust), recognized the need for
            a school that could adapt to the rapidly changing demands of the
            21st century while staying true to the timeless principles of
            integrity, respect, and lifelong learning. As we continue to grow,
            our focus remains on preparing our students not just for the
            challenges of today, but for the opportunities of tomorrow. At
            Rising Stars Public School, we are proud of what we have
            accomplished so far, and we are excited about the future we are
            creating together.
          </p>
        </section>
        <section className="mission-vision">
          <h2>Mission</h2>
          <p>
            At Rising Stars Public School, our mission is to inspire and empower
            every student to reach their full potential through a dynamic and
            inclusive educational experience. Our goal is to develop
            well-rounded individuals who are equipped with the knowledge,
            skills, and values needed to thrive in a rapidly changing world. We
            are committed to providing a supportive and collaborative
            environment where every student feels valued, respected, and
            prepared to make a positive impact in their community and beyond.
            The heart of Rising Stars Public School is our community—our
            students, teachers, parents, and staff—who bring energy, passion,
            and creativity to everything we do.
          </p>
        </section>
        <section className="mission-vision">
          <h2>Vision</h2>
          <p>
            Our vision at The Rising Stars Public School is to be a leader in
            innovative education, where students are challenged to explore their
            passions and develop the confidence to pursue their dreams. We
            envision a school where diversity is celebrated, where curiosity
            drives discovery, and where students are prepared to be lifelong
            learners and global citizens. We aim to create a nurturing and
            inclusive community that not only excels academically but also
            fosters empathy, resilience, and a strong sense of social
            responsibility. Through our commitment to excellence in education,
            we aspire to shape the leaders and changemakers of tomorrow.
          </p>
        </section>
        <section className="faculty">
          <h2>Our Faculty</h2>
          <p>
            At Rising Stars Public School, our faculty is the heart of our
            educational community. Comprising a diverse group of dedicated
            educators, our team brings a wealth of experience, expertise, and
            passion to the classroom. Our teachers are not just instructors but
            mentors who take the time to understand each student’s unique
            strengths and needs. They employ innovative teaching methods and use
            the latest educational technology to make learning interactive,
            relevant, and enjoyable. With a focus on fostering critical
            thinking, creativity, and collaboration, our faculty helps students
            develop the skills they need to succeed both academically and in
            life. Beyond their academic roles, our faculty members are active
            participants in the life of the school community. They lead
            extracurricular activities, coach sports teams, and organize clubs,
            providing students with a well-rounded experience that extends
            beyond the classroom. We are proud of our faculty’s achievements and
            their dedication to continuous professional development. By staying
            at the forefront of educational practices, our teachers ensure that
            Rising Stars Public School remains a place where students are
            inspired to learn, grow, and succeed.
          </p>
        </section>
      </div>
    </div>
  );
}

export default AboutUs;
